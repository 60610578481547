import { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import { HeaderBar } from "../components/pages";
import { Session, Row, Column } from "../styles/container";

import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";

import model from "../controllers/index"

//Images
import Icons from "../assets/icons";

import ReactGA from "react-ga4";

import { i18n } from "../translate/i18n";

ReactGA.initialize("G-Z0K7GSWF2F");

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Roboto;
    font-style: normal;
    }
`;

const HomeSessionStyle = styled(Session)`
  font-family: Rubik;
  font-style: normal;

  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("/assets/background_01.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .logo {
    width: 301px;
    margin: 112px auto 122px;
  }
`;

function HomeSession(params) {

  const isPortuguese = () => {
    if (localStorage.getItem("i18nextLng") === "pt-BR") {
      return true;
    } else {
      return false;
    }
  }

  return (
    <HomeSessionStyle style={{ textAlign: "center" }}>
      <img className="logo" src={isPortuguese() ? "/assets/logo.png" : "/assets/logo_english.png"} alt="" />
    </HomeSessionStyle>
  );
}

const AboutSessionStyle = styled(Session)`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  color: #000000;

  background: url("/assets/background_02.png");
  background-size: 100% auto;
  background-position: center;

  .values {
    display: flex;
    flex-direction: row;

    justify-content: space-around;
  }

  .value {
    margin-top: 20px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transform: rotate(-2.34deg);

    max-width: 203px;
    width: 100%;
    max-height: 227px;
    height: 100%;

    text-align: center;
    padding: 20px;

    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    user-select: none;
    color: #000000;

    img {
      width: 59px;
      height: 59px;
    }

    :hover {
      cursor: pointer;
      border: 1px solid #818181;
    }

    .title {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;

      color: #000000;

      margin: 13px auto;
    }
  }

  .text {
    font-size: 17px;
    line-height: 20px;
    text-align: justify;
    font-weight: 500;
    color: #000000;
  }

  .title {
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    font-weight: 500;
    color: #000000;
    margin-bottom: 42px;
  }

  ${Row} {
    justify-content: space-around;
  }

  @media screen and (max-width: 768px) {
    .values {
      flex-wrap: wrap;
    }

    .about {
      display: none;
    }
  }
`;

function AboutSession(params) {
  return (
    <AboutSessionStyle {...params} style={{ textAlign: "center" }}>
      <Row>
        <img className="about" src="/assets/about.png" alt="" />
        <Column style={{ maxWidth: 749 }}>
          <div className="title">{i18n.t("about.title") || ""}</div>
          <div className="text">
            {i18n.t("about.description") || ""}
          </div>
          <div className="values">
            <div className="value">
              <img src="/assets/mission.png" alt="" />
              <div className="title">{i18n.t("about.missionTitle") || ""}</div>
              {i18n.t("about.missionDescription") || ""}
            </div>
            <div className="value">
              <img src="/assets/vision.png" alt="" />
              <div className="title">{i18n.t("about.visionTitle") || ""}</div>
              {i18n.t("about.visionDescription") || ""}
            </div>
            <div className="value">
              <img src="/assets/values.png" alt="" />
              <div className="title">{i18n.t("about.valuesTitle") || ""}</div>
              {i18n.t("about.valuesDescription") || ""}
            </div>
          </div>
        </Column>
      </Row>
    </AboutSessionStyle>
  );
}

const TeamSessionStyle = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  color: #000000;

  background: url("/assets/background_02.png");
  background-size: 100% auto;
  background-position: center;

  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: justify;

    color: #000000;
    margin-bottom: 39px;
    margin-left: 102px;
  }

  ${Row} {
    justify-content: space-around;
    flex-wrap: wrap;
  }
`;

const TeamCard = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  color: #000000;

  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  position: relative;
  width: 367px;
  padding: 16px 1px 13px 84px;
  margin-bottom: 64px;

  :hover {
    cursor: pointer;
    border: 1px solid #818181;
  }

  .title {
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: justify;

    color: #000000;
    margin: 0px;
  }

  .subtitle {
    font-family: Rubik;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;

    color: #000000;
    margin-top: 10px;
    margin-bottom: 11px;
    min-height: 35px;
  }

  img {
    width: 108px;
    height: 108px;
    border-radius: 54px;
    position: absolute;
    left: -39px;
    top: -26px;
    object-fit: cover;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 16px 1px 13px 104px;
    margin-bottom: 44px;

    img {
      left: -19px;
    }
  }
`;

function TeamSession(params) {
  const Team = [
    {
      profile: "/assets/team_macello.jpeg",
      title: "Macello Medeiros",
      subtitle: `${i18n.t("team.membersList.macello.position") || ""}`,
    },
    {
      profile: "/assets/team_sinezio.jpeg",
      title: "Sinézio Morais",
      subtitle: `${i18n.t("team.membersList.sinezio.position") || ""}`,
    },
    {
      profile: "/assets/team_vinicius.jpeg",
      title: "Vinícius Meirelles",
      subtitle: `${i18n.t("team.membersList.vinicius.position") || ""}`,
    },
    {
      profile: "/assets/team_delcimario.jpg",
      title: "Delcimario Alves",
      subtitle: `${i18n.t("team.membersList.delcimario.position") || ""}`,
    },
    {
      profile: "/assets/team_ricardo.jpeg",
      title: "Ricardo Teixeira",
      subtitle: `${i18n.t("team.membersList.ricardo.position") || ""}`,
    },
    {
      profile: "/assets/team_alvaro.jpg",
      title: "Álvaro Oliveira",
      subtitle: `${i18n.t("team.membersList.alvaro.position") || ""}`,
    },
    {
      profile: "/assets/team_bruno.jpg",
      title: "Bruno Correia",
      subtitle: `${i18n.t("team.membersList.bruno.position") || ""}`,
    },
    {
      profile: "/assets/team_rubis.jpg",
      title: "Rubisley Lemes",
      subtitle: `${i18n.t("team.membersList.rubis.position") || ""}`,
    },
    {
      profile: "/assets/team_elton.jpg",
      title: "Elton Andrade",
      subtitle: `${i18n.t("team.membersList.elton.position") || ""}`,
    },
  ];

  return (
    <TeamSessionStyle {...params}>
      <Session>
        <div className="title">{i18n.t("team.title") || ""}</div>
        <Row>
          {Team.map((team, index) => (
            <TeamCard key={index}>
              <img src={team.profile} alt="" style={{ marginLeft: "5px" }} />
              <div className="title">{team.title}</div>
              <div className="subtitle">{team.subtitle}</div>
            </TeamCard>
          ))}
        </Row>
      </Session>
    </TeamSessionStyle>
  );
}

const Information = styled.div`
  width: 100%;
  background: #fffcdd;
  height: 185px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .info {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: justify;
    margin: auto;
    color: #000000;
  }

  .description {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;

    color: #000000;
  }
`;

const ContactSessionStyle = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  color: #000000;

  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("/assets/background_03.png");
  background-size: auto 100%;
  background-position: center;

  ${Row} {
    justify-content: space-around;
  }

  ${Session} {
    height: ${() => "calc(100% - 185px)"};
    min-height: auto;
  }

  .contact {
    max-width: 1106px;
    margin: auto;
    padding: 35px;
    border: 1px solid #ffffff;
    box-sizing: border-box;

    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;

      color: #ffffff;
      margin-bottom: 29px;
    }

    .content {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      line-height: 29px;

      color: #ffffff;

      .item {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-bottom: 5px;
        svg {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          path {
            stroke: #ffffff;
          }
        }
      }
    }

    ${Row} {
      align-items: flex-start;

      .logo {
        width: 60%;
        object-fit: contain;
      }

      #dome-ventures {
        width: 35%;
        margin-left: 10%;
      }

    }

    ${Column} {
      .awards {
        display: flex;
        flex-direction: column;
      }
    }

    .training {
      align-items: flex-start;
      padding-top: 15px;
      ${Column} {
        align-self: stretch;
        align-items: center;
        justify-content: space-between;
      }

      .logo {
        width: 80%;
        object-fit: contain;
      }

      #btg-soma {
        width: 60%;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .contact {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      ${Row} {
      #dome-ventures {
        margin-left: 0;
      }

    }
    }
  }
`;

function ContactSession(params) {
  return (
    <ContactSessionStyle {...params}>
      <Information>
        <div className="info">{i18n.t("joinTheTeam.title") || ""}</div>
        <div className="description">
          {i18n.t("joinTheTeam.subtitle") || ""}
        </div>
      </Information>
      <Session>
        <div className="contact">
          <Row>
            <Column
              style={{ alignSelf: "stretch", justifyContent: "space-between" }}
            >
              <div>
                <div className="title">{i18n.t("contact.title") || ""}</div>
                <div className="content">
                  <div className="item">
                    <Icons.Mail />
                    inpatics@gmail.com
                  </div>
                  <div className="item">
                    <Icons.Linkedin />
                    company/inpatics
                  </div>
                  <div className="item">
                    <Icons.Instagram />
                    @inpatics
                  </div>
                </div>
              </div>
              <div>
                <div className="title">{i18n.t("contact.awards") || ""}</div>
                <div className="awards" style={{ marginBottom: 15 }}>
                  <img
                    className="logo"
                    src="/assets/award_01.jpeg"
                    alt=""
                  />
                  <img
                    id="dome-ventures"
                    className="logo"
                    src="/assets/award_02.png"
                    alt=""
                    style={{ marginTop: 25 }}
                  />
                </div>
              </div>
            </Column>
            <Column
              style={{
                backgroundColor: "#fff",
                padding: "10px",
                borderRadius: 4,
              }}
            >
              <div style={{ fontWeight: 700, }}>{i18n.t("contact.training") || ""}</div>
              <Row className="training">
                <Column>
                  <img
                    className="logo"
                    src="/assets/support_01.jpeg"
                    style={{ paddingTop: 15 }}
                    alt=""
                  />
                  <img
                    className="logo"
                    src="/assets/support_03.png"
                    style={{ paddingTop: 15 }}
                    alt=""
                  />
                  <img
                    className="logo"
                    src="/assets/support_05.png"
                    style={{ paddingTop: 15 }}
                    alt=""
                  />
                </Column>
                <Column>
                  <img
                    className="logo"
                    src="/assets/support_02.jpg"
                    alt=""
                  />
                  <img
                    className="logo"
                    src="/assets/support_04.png"
                    alt=""
                  />
                  <img
                    className="logo"
                    src="/assets/support_06.jpeg"
                    alt=""
                  />
                  <img
                    id="btg-soma"
                    className="logo"
                    src="/assets/support_07.png"
                    alt=""
                  />
                </Column>
              </Row>
              {/* <Input>
                <label>Nome</label>
                <input type="text" name="name" />
              </Input>
              <Input>
                <label>Email</label>
                <input type="text" name="name" />
              </Input>
              <Input>
                <label>Mensagem:</label>
                <textarea type="text" name="name" rows={3} />
              </Input> */}
            </Column>
          </Row>
        </div>
      </Session>
    </ContactSessionStyle>
  );
}

const ProductSessionStyle = styled.div`
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("/assets/background_04.png");

  ${Session} {
    padding: 38px 0px;
  }

  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #ffffff;
    margin-bottom: 20px;
    margin-left: 102px;
  }

  .product {
    max-width: 1106px;
    margin: 23px auto 50px;
    padding: 35px;
    background: #ffffff;
    box-sizing: border-box;
    min-height: 490px;

    display: flex;
    align-items: center;

    ${Row} {
      justify-content: space-around;
    }

    ${Column} {
      width: auto;
    }

    .logo_zone {
      img {
        object-fit: contain;
        max-width: 437px;
        max-height: 420px;
      }
    }

    .description {
      max-width: 529px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      text-align: justify;

      color: #000000;
    }

    .title {
      font-weight: 700;
      font-size: 36px;
      line-height: 43px;
      text-align: center;
      color: #000000;
      margin: auto auto 17px;
    }
  }

  @media screen and (max-width: 768px) {
    .product {
      .logo_zone {
        img {
          object-fit: contain;
          max-width: 100%;
          max-height: 320px;
          padding: 0px 5px;
          box-sizing: border-box;
        }
      }
    }
  }
`;

function ProductSession(params) {
  const products = [
    {
      source: "/assets/product_07.png",
      title: `${i18n.t("products.productsList.platformTitle") || ""}`,
      link: "https://www.izzistrit.com/",
      description:
        `${i18n.t("products.productsList.platformDescription") || ""}`,
    },
    {
      source: "/assets/product_06.jpg",
      title: `${i18n.t("products.productsList.projectsTitle") || ""}`,
      description:
        `${i18n.t("products.productsList.projectsDescription") || ""}`,
    },
    {
      source: "/assets/product_01.jpg",
      title: `${i18n.t("products.productsList.reportsTitle") || ""}`,
      description:
        `${i18n.t("products.productsList.reportsDescription") || ""}`,
    },
    {
      source: "/assets/product_02.jpg",
      title: `${i18n.t("products.productsList.simulationsTitle") || ""}`,
      description:
        `${i18n.t("products.productsList.simulationsDescription") || ""}`,
    },
    {
      source: "/assets/product_03.jpg",
      title: `${i18n.t("products.productsList.spreadsheetsTitle") || ""}`,
      description:
        `${i18n.t("products.productsList.spreadsheetsDescription") || ""}`,
    },
    {
      source: "/assets/product_04.jpg",
      title: `${i18n.t("products.productsList.infographicsTitle") || ""}`,
      description:
        `${i18n.t("products.productsList.infographicsDescription") || ""}`,
    },
    {
      source: "/assets/product_05.jpg",
      title: `${i18n.t("products.productsList.stampTitle") || ""}`,
      description:
        `${i18n.t("products.productsList.stampDescription") || ""}`,
    },
  ];

  return (
    <ProductSessionStyle {...params}>
      <Session>
        <div className="title">{i18n.t("products.title") || ""}</div>
        <Carousel interval={500000000}>
          {products.map((product, index) => (
            <Carousel.Item key={index}>
              <div className="product">
                <Row>
                  <a href={product.link}>
                    <div className="logo_zone">
                      <img src={product.source} alt="" />
                    </div>
                  </a>
                  <Column>
                    <div className="title">{product.title}</div>
                    <div className="description">{product.description}</div>
                  </Column>
                </Row>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Session>
    </ProductSessionStyle>
  );
}

const SupportSessionStyle = styled.div`
  img {
    width: 10%;
    object-fit: contain;
  }
  ${Row} {
    align-items: flex-start;
  }

  ${Column} {
    margin-top: 10px;
    padding: 0px 5px;
    font-weight: 700;
    font-size: 18px;

    ${Row} {
      justify-content: space-around;
      align-items: center;
    }

    .text {
      margin-left: 20px;
      user-select: none;
    }
  }

  @media screen and (max-width: 768px) {
    ${Column} {
      ${Row} {
        flex-direction: row;
      }
    }
  }
`;

function SupportSession(params) {
  return (
    <SupportSessionStyle {...params}>
      <Row>
        <Column>
          <div className="text">{i18n.t("fomentation.title") || ""}</div>
          <Row>
            <img src="/assets/sponsorship_01.png" alt="" />
            <img src="/assets/sponsorship_02.jpg" alt="" />
            <img src="/assets/sponsorship_03.jpeg" alt="" />
          </Row>
        </Column>
      </Row>
    </SupportSessionStyle>
  );
}

const CitiesSessionStyle = styled.div`
  width: 100%;
  min-height: 100%;
  text-align: center;
  padding: 3%;

  ${Session} {
    padding: 38px 0px;
  }

  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: left;
    line-height: 29px;
    color: #000;
  }

  .box {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .city {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    background-color: #545454;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
  }

  .numbers {
    font-family: "Horta", sans-serif;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10px;
    text-shadow: 1.5px 1.5px 1px #4c74bc, 3.5px 3.5px 1px #b4ccfc;
  }

  .text {
    font-family: Montserrat;
    font-size: 14px;
    margin-top: 4px;
    font-weight: 550;
  }
`;

function CitiesSession(params) {
  const [infos, setInfos] = useState([]);

  useEffect(() => {
    model.statists().then((results) => {
      let auxArray = [];
      results.data.data.forEach((element) => {
        const aux = {
          cidade: element.name,
          coleta: [
            {
              title: `${i18n.t("cities.citiesList.equipaments.bussStop") || ""}`,
              number: element.pontos_de_onibus,
            },
            {
              title: `${i18n.t("cities.citiesList.equipaments.trafficLights") || ""}`,
              number: element.semaforos,
            },
            {
              title: `${i18n.t("cities.citiesList.equipaments.crosswalks") || ""}`,
              number: element.faixa_de_pedestres,
            },
            {
              title: `${i18n.t("cities.citiesList.equipaments.walkways") || ""}`,
              number: element.passarelas,
            },
            {
              title: `${i18n.t("cities.citiesList.equipaments.elevatorsLifts") || ""}`,
              number: element.ascensores,
            },
          ]
        };
        auxArray.push(aux);
      });
      setInfos(auxArray);
    }).catch((error) => {
      console.log(error);
    })
  }, [])

  return (
    <CitiesSessionStyle {...params}>
      <Row>
        <div className="title">{i18n.t("cities.title") || ""}</div>
      </Row>
      {infos.map((cidade, index) => (
        <div key={index} className="box">
          <Row>
            <div className="city">{cidade.cidade}</div>
          </Row>
          <Row>
            <div className="text">{i18n.t("cities.citiesList.subtitle") || ""}</div>
          </Row>
          <Row>
            {cidade.coleta.map((get, index) => (
              <Column key={index}>
                <div className="numbers">{get.number}</div>
                <div className="text">{get.title}</div>
              </Column>
            ))}
          </Row>
        </div>
      ))}
    </CitiesSessionStyle>
  );
}

function Home() {
  useEffect(() => ReactGA.send({ hitType: "pageview", page: "/" }), []);

  return (
    <>
      <GlobalStyle />
      <HeaderBar />
      <HomeSession />
      <AboutSession id="about" />
      <ProductSession id="product" />
      <CitiesSession id="cities" />
      <TeamSession id="team" />
      <ContactSession id="contact" />
      <SupportSession />
    </>
  );
}

export default Home;
