import axios from "axios";

//const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3333";
const API_URL = "https://izzistrit-backend.azurewebsites.net";

const api = axios.create({
    baseURL: API_URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
    },
});

export default api;
