const messages = {
  en: {
    translations: {
      bar: {
        aboutLink: "About",
        productsLink: "Products",
        citiesLink: "Cities",
        teamLink: "Team",
        contactLink: "Contacts",
      },
      about: {
        title: "Who We Are",
        description:
          'Inpatics was born with the purpose of making cities more empathetic, humane, intelligent, and sustainable through information and communication technologies, aiming to improve people\'s relationships with each other and their environment. This motivation arose during the development of the "Ponto Certo" research project in 2013, which mapped bus stops in Salvador and classified them according to accessibility conditions. The idea of creating "Ponto Certo" originated from an initiative by the Accessibility and Mobility GT of CREA-BA, of which Inpatics\' partners are members. Listening to the challenges faced by people with disabilities or reduced mobility in their daily lives, we decided to do more. We are an urban tech company created to collect, analyze, and give visibility to urban data through technological solutions.',
        missionTitle: "Mission",
        missionDescription:
          "Propose technological solutions based on urban data",
        visionTitle: "Vision",
        visionDescription:
          "Become a reference company in the field of urban data",
        valuesTitle: "Values",
        valuesDescription: "Empathy, Expertise, Reliability, Innovation",
      },
      products: {
        title: "Products",
        productsList: {
          platformTitle: "Platform",
          platformDescription:
            "Izzistrit! is an urban data indexing platform that assists people with disabilities or reduced mobility in safely navigating urban spaces with independence and autonomy. Through digital maps, chatbot, and AI-powered application, we aim to provide information that reduces challenges and barriers in their daily lives, from suggesting the most suitable routes based on each disability to recommending accessible commercial establishments (ramps, tactile pavements, parking spaces, etc).",
          projectsTitle: "Projects",
          projectsDescription:
            "Development of accessibility projects, providing descriptive interventions with photographic records. This includes: a) Measuring actual conditions at the location; b) Identifying places (points) that do not comply with the law, detailing suggestions and intervention recommendations.",
          reportsTitle: "Reports",
          reportsDescription:
            "Creation of descriptive reports containing a diagnosis of accessibility conditions data, accompanied by photographic records of one or more urban facilities or commercial establishments in any location, based on the proposed analysis indicators.",
          simulationsTitle: "3D Simulation",
          simulationsDescription:
            "Creation of a digital model showing proposed interventions. Through animations, clients can visualize the proposed interventions comprehensively and in detail, along with making changes before implementation.",
          spreadsheetsTitle: "Spreadsheets",
          spreadsheetsDescription:
            "Creation of spreadsheets with data on accessibility conditions of one or more urban facilities in any location, based on proposed analysis indicators.",
          infographicsTitle: "Infographics",
          infographicsDescription:
            "Creation of infographics to better visualize data related to accessibility conditions of one or more urban facilities in any location, based on proposed analysis indicators.",
          stampTitle: '"I Am Accessible" Seal',
          stampDescription:
            "Certification of commercial establishments, seeking recognition as a socially responsible company. This service can be integrated with an accessibility project.",
        },
      },
      cities: {
        title: "Cities in Numbers",
        citiesList: {
          subtitle: "Inspected Facilities",
          equipaments: {
            bussStop: "Bus Stops",
            trafficLights: "Traffic Lights",
            crosswalks: "Crosswalks",
            walkways: "Walkways",
            elevatorsLifts: "Elevators/Lifts",
          },
        },
      },
      team: {
        title: "Team",
        membersList: {
          generic: {
            title: "To Be Hired",
            subtitle: "Field Agent",
          },
          elton: {
            position:
              "Consultant in the field of urban planning and urban accessibility",
          },
          macello: {
            position: "CEO/Founder",
          },
          sinezio: {
            position: "Backend Developer",
          },
          delcimario: {
            position: "Database Administrator (DBA)",
          },
          rubis: {
            position: "Consulting in the area of Image Processing and CV",
          },
          vinicius: {
            position: "Frontend Developer",
          },
          ricardo: {
            position: "Data Analyst",
          },
          alvaro: {
            position: "Data Analyst",
          },
          bruno: {
            position: "Data Analyst",
          },
        },
      },
      joinTheTeam: {
        title: "Join the Team",
        subtitle: "We will soon announce new opportunities",
      },
      contact: {
        title: "Contact",
        training: "Incubation and Acceleration",
        awards: "Awards",
      },
      fomentation: {
        title: "Promotion",
      },
    },
  },
};

export { messages };
