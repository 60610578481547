const messages = {
  pt: {
    translations: {
      bar: {
        aboutLink: "Sobre",
        productsLink: "Produtos",
        citiesLink: "Cidades",
        teamLink: "Equipe",
        contactLink: "Contatos",
      },
      about: {
        title: "O que somos",
        description:
          'A Inpatics nasceu com o propósito de tornar as cidades mais empáticas, humanas, inteligentes e sustentáveis. por meio das tecnologias de informação e comunicação visando melhorar a relação das pessoas com outras pessoas e também com o seu ambiente. Essa motivação surgiu durante o desenvolvimento do projeto de pesquisa "Ponto Certo" em 2013 que mapeou os pontos de ônibus de Salvador e classificou-os quanto às condições de acessibilidade. A ideia de criar o "Ponto Certo" teve origem numa ação do GT de Acessibilidade e Mobilidade do CREA-BA, do qual os sócios da Inpatics fazem parte. E foi ouvindo os relatos dos desafios enfrentados pelas pessoas com deficiência ou mobilidade reduzida no seu dia a dia que decidimos fazer mais. Somos uma urban tech criada para coletar, analisar e dar visibilidade aos dados urbanos por meio de soluções tecnológicas.',
        missionTitle: "Missão",
        missionDescription:
          "Propor soluções tecnológicas baseadas em dados urbanos",
        visionTitle: "Visão",
        visionDescription:
          "Tornar-se uma empresa referência na área de dados urbanos",
        valuesTitle: "Valores",
        valuesDescription: "Empatia, Especialização, confiabilidade, inovação",
      },
      products: {
        title: "Produtos",
        productsList: {
          platformTitle: "Plataforma",
          platformDescription:
            "A Izzistrit! é um plataforma de indexação de dados urbanos que auxilia a tomada de decisão das pessoas com deficiência ou mobilidade reduzida no deslocamento pelo espaço urbano com segurança, independência e autonomia. Por meio de mapas digitais, chatbot e aplicativo com Inteligência Artificial, queremos gerar informações que diminuam os desafios e barreiras existentes no dia a dia dessas pessoas, desde a indicação do trajeto mais favorável de acordo com cada deficiência, até recomendações de estabelecimentos comerciais, levando em conta suas condições de acessibilidade (rampas, piso tátil, vagas de estacionamento, etc).",
          projectsTitle: "Projetos",
          projectsDescription:
            "Elaboração de projetos de acessibilidade indicando de maneira dissertativa e com registros fotográficos quais são as propostas de intervenção para realizar as devidas adequações, considerando: a) Medição das condições reais do local; b) Identificação dos locais (pontos) que não estão adequados à lei, com detalhamento e sugestões/indicações de intervenção.",
          reportsTitle: "Relatórios",
          reportsDescription:
            "Elaboração de relatórios descritivos constando o diagnóstico dos dados sobre as condições de acessibilidade, acompanhados de registros fotográficos de um ou mais equipamentos urbanos ou estabelecimentos comerciais em qualquer localidade com base nos indicadores de análise propostas na metodologia.",
          simulationsTitle: "Simulação 3D",
          simulationsDescription:
            "Criação de modelo digital mostrando a proposta de intervenção para realizar as devidas adequações. A partir das animações, os clientes visualizam de maneira ampla e detalhista as intervenções propostas, bem como a realização de alterações antes da sua execução.",
          spreadsheetsTitle: "Planilhas",
          spreadsheetsDescription:
            "Elaboração de planilhas com dados sobre as condições de acessibilidade de um ou mais equipamentos urbanos em qualquer localidade com base nos indicadores de análise propostas na metodologia.",
          infographicsTitle: "Infográficos",
          infographicsDescription:
            "Elaboração de infográficos para melhor visualizar os dados relativos às condições de acessibilidade de um ou mais equipamentos urbanos em qualquer localidade com base nos indicadores de análise propostas na metodologia.",
          stampTitle: 'Selo "Eu Sou Acessível"',
          stampDescription:
            "Certificação de estabelecimentos comerciais, buscando reconhecimento do estabelecimento como empresa de responsabilidade social. Esse serviço pode estar agregado à elaboração de um projeto de acessibilidade.",
        },
      },
      cities: {
        title: "Cidades em números",
        citiesList: {
          subtitle: "Equipamentos inspecionados",
          equipaments: {
            bussStop: "Pontos de Ônibus",
            trafficLights: "Semáforos",
            crosswalks: "Faixas de Pedestre",
            walkways: "Passarelas",
            elevatorsLifts: "Elevadores/Ascensores",
          },
        },
      },
      team: {
        title: "Equipe",
        membersList: {
          generic: {
            title: "A contratar",
            subtitle: "Agente de campo",
          },
          elton: {
            position:
              "Consultor na área de planejamento urbano e acessibilidade urbana",
          },
          macello: {
            position: "CEO/Fundador",
          },
          sinezio: {
            position: "Desenvolvedor Backend",
          },
          delcimario: {
            position: "Administrador de Banco de Dados (DBA)",
          },
          rubis: {
            position: "Consultor na área de Processamento de Imagens e VC",
          },
          vinicius: {
            position: "Desenvolvedor Frontend",
          },
          ricardo: {
            position: "Analista de Dados",
          },
          alvaro: {
            position: "Analista de Dados",
          },
          bruno: {
            position: "Analista de Dados",
          },
        },
      },
      joinTheTeam: {
        title: "Junte-se a equipe",
        subtitle: "Em breve divulgaremos novas oportunidades",
      },
      contact: {
        title: "Contato",
        training: "Incubação e Aceleração",
        awards: "Prêmios",
      },
      fomentation: {
        title: "Fomento",
      },
    },
  },
};

export { messages };
