import styled, { createGlobalStyle } from "styled-components";
import { Link } from "react-scroll";

import { i18n } from "../translate/i18n";

const GlobalStyle = createGlobalStyle`
    *{
       margin: 0px;
       padding: 0px; 
    }
`;

export const Item = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  float: right;

  padding: 0px 19px;

  cursor: pointer;

  @media screen and (max-width: 768px) {
    padding: 0px 10px;
  }
`;

export const Bar = styled.div`
  background: rgba(34, 34, 34, 0.52);
  box-sizing: border-box;

  width: 100%;
  height: 47px;

  display: flex;
  vertical-align: center;
  justify-content: end;

  position: fixed;
  z-index: 6;
  img {
    width: 156px;
    height: 66px;

    padding: 15px 0px 15px 36px;
  }

  @media screen and (max-width: 768px) {
    justify-content: space-around;
  }
`;

export function HeaderBar(props) {
  return (
    <>
      <GlobalStyle />
      <Bar>
        {/* <img src={} alt="Logo do AuX" /> */}
        <Item>
          <Link to="about" smooth={true} offset={0} duration={500}>
            {i18n.t("bar.aboutLink") || ""}
          </Link>
        </Item>
        <Item>
          <Link to="product" smooth={true} offset={0} duration={500}>
            {i18n.t("bar.productsLink") || ""}
          </Link>
        </Item>
        <Item>
          <Link to="cities" smooth={true} offset={0} duration={500}>
            {i18n.t("bar.citiesLink") || ""}
          </Link>
        </Item>
        <Item>
          <Link to="team" smooth={true} offset={0} duration={500}>
            {i18n.t("bar.teamLink") || ""}
          </Link>
        </Item>
        <Item>
          <Link to="contact" smooth={true} offset={0} duration={500}>
            {i18n.t("bar.contactLink") || ""}
          </Link>
        </Item>
      </Bar>
    </>
  );
}
