import styled from "styled-components";

export const Line = styled.div`
  margin: 11px 0px;
  width: 100%;
  border: 1px solid #d1d1d1;
`;

export const Row = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: inline-flex;
  width: 100%;
  flex-direction: column;
`;

export const Session = styled.div`
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : "38px 52px")};
  min-height: 100vh;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 38px 15px;
  }
`;
